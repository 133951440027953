@font-face {
	font-family: 'Century Gothic';
	font-weight: 400;
	font-style: normal;

	src: url('../fonts/CenturyGothic/CenturyGothic.woff2') format('woff2'),
	url('../fonts/CenturyGothic/CenturyGothic.woff') format('woff');
}

@font-face {
	font-family: 'Century Gothic';
	font-weight: 400;
	font-style: italic;

	src: url('../fonts/CenturyGothic/CenturyGothic-Italic.woff2') format('woff2'),
	url('../fonts/CenturyGothic/CenturyGothic-Italic.woff') format('woff');
}

@font-face {
	font-family: 'Century Gothic';
	font-weight: 700;
	font-style: normal;

	src: url('../fonts/CenturyGothic/CenturyGothic-Bold.woff2') format('woff2'),
	url('../fonts/CenturyGothic/CenturyGothic-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Century Gothic';
	font-weight: 700;
	font-style: italic;

	src: url('../fonts/CenturyGothic/CenturyGothic-BoldItalic.woff2') format('woff2'),
	url('../fonts/CenturyGothic/CenturyGothic-BoldItalic.woff') format('woff');
}

