*,
:before,
:after {
	box-sizing: border-box;
}

html {
	font-family: $arial;
	font-size: 14px;
	line-height: 1.4286;

	display: flex;

	min-height: 100%;

	color: $black;
	background: white;

	&.fixed {
		overflow-y: hidden;

		height: 100%;
	}

	&.has-scrollbar {
		overflow-y: scroll;
	}
}

body {
	display: flex;
	flex-direction: column;

	width: 100%;
	min-width: 320px;

	&.mobile {
		cursor: pointer;
	}

	html.fixed & {
		overflow: hidden;
	}
}

a {
	transition: color $t;

	color: $red;

	body:not(.mobile) &:hover {
		text-decoration: none;
	}
}

h1,
h2 {
	@include media('<sm') {
		font-size: 20px;

		letter-spacing: .1em;
	}

	font-family: $centurygothic;
	font-size: 24px;
	font-weight: normal;
	line-height: 1.25;

	margin: 0 0 1.25em;

	letter-spacing: .2em;
	text-transform: uppercase;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}
