// Fonts
$centurygothic: 'Century Gothic', sans-serif;
$arial: Arial, sans-serif;

// Colors
$black: #141414;
$red: #f03;
$grey: #ccc;

// Transition
$t: .2s ease-in-out;

// Breakpoints
$breakpoints: (
	'xs': 640px,
	'sm': 768px,
	'md': 1024px,
	'lg': 1260px
);
